import CreatorService from '@/services/creator.service';
import AxiosApiService from '@/services/axios/axios.api.service';

const FormService = {
  ...CreatorService.createCrud(AxiosApiService, 'form', 'forms', '/forms'),
  pushForm(id) {
    return AxiosApiService.post(`/pools/stack?id=${id}`, {});
  },
  pushFormForAll(id) {
    return AxiosApiService.post(`/pools/stack-all?id=${id}`, {});
  },
};

export default FormService;
