const PAGE_HEADER = 'x-page';
const SIZE_HEADER = 'x-elements-per-page';
const PAGES_HEADER = 'x-page-count';
const ELEMENTS_HEADER = 'x-element-count';

const PaginationUtils = {
  fromHeaders(headers) {
    const values = [
      this.getHeader(PAGE_HEADER, headers),
      this.getHeader(SIZE_HEADER, headers),
      this.getHeader(PAGES_HEADER, headers),
      this.getHeader(ELEMENTS_HEADER, headers),
    ];

    if (values.some((v) => !v || typeof v !== 'string' || !v.match(/\d+/))) {
      return null;
    }

    const [page, size, pageCount, elementCount] = values.map((v) => Number.parseInt(v, 10));
    return {
      page, size, pageCount, elementCount,
    };
  },

  fromMeta(meta) {
    if (!meta || !meta.pagination) {
      return null;
    }
    const page = meta.pagination.page || 1;
    const pageCount = meta.pagination.pages || 1;
    const size = (meta.pagination.total / pageCount) || 1;
    const elementCount = meta.pagination.total || 0;

    return {
      page, size, pageCount, elementCount,
    };
  },

  getHeader(headerKey, headers) {
    const key = Object.keys(headers).find((k) => k.toLowerCase() === headerKey);
    return key ? headers[key] : null;
  },
};

export default PaginationUtils;
