import AxiosApiService from '../axios/axios.api.service';

let fakeCalls = -1;

const ApiService = {

  // QUESTIONS

  /**
   * Fetches all the questions from the API.
   */
  async fetchQuestions() {
    return AxiosApiService.get('/questions');
  },

  /**
   * Creates a new question using the API.
   * @param {Object} question The new question to create.
   */
  async createQuestion(question) {
    return AxiosApiService.post('/questions', question);
  },

  /**
   * Deletes the given question from the API.
   * @param {String/Number} id The id of the question to delete.
   */
  async deleteQuestion(id) {
    return AxiosApiService.delete(`/questions/${id}`);
  },

  // EVENTS

  /**
   * Fetches all the events from the API.
   */
  async fetchEvents() {
    return AxiosApiService.get('/events');
  },

  /**
   * Fetch the event corresponding to the given ID.
   * @param {*} id The id of the event to retrieve.
   */
  async fetchEvent(id) {
    return AxiosApiService.get(`/events/${id}`);
  },

  /**
   * Creates a new event using the API.
   * @param {Object} event The new event to create.
   */
  async createEvent(event) {
    return AxiosApiService.post('/events', event);
  },

  /**
   * Deletes the event  from the API.
   * @param {*} id The id of the event to delete.
   */
  async deleteEvent(id) {
    return AxiosApiService.delete(`/events/${id}`);
  },

  /**
   * Gets the export data.
   * @returns {Promise<*>}
   */
  async fetchExportData() {
    return AxiosApiService.get('/export');
  },

  /**
   * Gets the export data for a given form.
   * @param {String} formId The id of the form to export.
   * @returns {Promise<*>}
   */
  async fetchExportDataByForm(formId) {
    return AxiosApiService.get(`/export/${formId}`);
  },

  // HELPERS
  async fake(data, empty, duration = 1500, random = false) {
    fakeCalls = (fakeCalls + 1) % 3;
    return new Promise((resolve) => { setTimeout(() => { resolve(); }, duration); })
      .then(() => {
        let value = fakeCalls;

        if (random) {
          const n = (Math.random() * 100);
          if (n < 80) {
            value = 0;
          } if (n < 90) {
            value = 1;
          } else {
            value = 2;
          }
        }

        switch (value) {
          case 1: return { data: empty !== undefined ? empty : data };
          case 2: throw new Error('Failed');
          default: return { data };
        }
      });
  },
};

export default ApiService;
