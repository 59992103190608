<template>
  <v-row>
    <v-col align="center">
      <h1>{{ $t('common.messages.welcome', {app_name: 'PREGrec'}) }}</h1>
      <br>

      <v-card>
        <v-card-title>
          {{ $t('common.messages.export_options') }}
        </v-card-title>

        <v-card-text>
          <v-select
            id="formSelector"
            v-model="selectedForm"
            :options="formList()"
            :items="formList()"
            :label="$t('common.words.form')"
            v-if="forms.length > 0"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.value !== 'all'">
                {{ $t('common.words.form') }} #{{ item.value }} {{ item.label }}
              </span>
              <span v-else>
                {{ item.label }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.value !== 'all'">
                {{ $t('common.words.form') }} #{{ item.value }} {{ item.label }}
              </span>
              <span v-else>
                {{ item.label }}
              </span>
            </template>
            <template v-slot:append-item>
              <paginator
                v-if="fetchMixin.pagination"
                :pagination="fetchMixin.pagination"
                @update="updatePagination"
              />
            </template>
          </v-select>

          <v-row style="display: flex; justify-content: space-around">
            <v-checkbox
              v-model="selected"
              v-bind:label="$t('common.words.answers')"
              value="0">
            </v-checkbox>
            <v-checkbox
              v-model="selected"
              v-bind:label="$t('common.words.forms')"
              value="1">
            </v-checkbox>
            <v-checkbox
              v-model="selected"
              v-bind:label="$t('common.words.questions')"
              value="2">
            </v-checkbox>
          </v-row>
        </v-card-text>

        <v-card-actions style="display: flex; justify-content: center">
          <v-btn depressed color="primary" @click="exportDb">
            <span v-if="!loading">
                <v-icon>mdi-file-download-outline</v-icon>
                {{ $t('common.action.download') }}
            </span>
            <pulse-loader v-else :loading="loading" :color="'#ffffff'" :size="'8px'"></pulse-loader>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from '@/services/api/api.service';
import Utils from '@/utils/utils';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import FetchMixin from '@/mixins/fech.mixin';
import FormService from '@/services/api/form.service';
import Paginator from '../components/util/paginator.vue';

export default {
  name: 'Home',
  mixins: [FetchMixin],
  components: {
    Paginator,
    PulseLoader,
  },
  data() {
    return {
      selected: ['0'],
      loading: false,
      forms: [],
      selectedForm: 'all',
    };
  },
  methods: {
    formList() {
      return [
        { label: this.$t('common.words.all'), value: 'all' },
        ...this.forms.map((form) => ({ label: form.label, value: form.id })),
      ];
    },
    fetchForms() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      Utils.scrollToTop();
      return FormService.fetchForms({ page, size });
    },
    exportDb() {
      let data = [];
      this.loading = true;
      if (this.selectedForm === 'all') {
        ApiService.fetchExportData()
          .then((response) => {
            data = response.data;
          })
          .finally(() => {
            this.downloadFiles(data);
            this.loading = false;
          });
      } else {
        ApiService.fetchExportDataByForm(this.selectedForm)
          .then((response) => {
            data = response.data;
          })
          .finally(() => {
            this.downloadFiles(data);
            this.loading = false;
          });
      }
    },
    downloadFiles(jsonData) {
      const answerKeys = [
        `\uFEFF${this.$t('common.labels.account')}`,
        this.$t('common.labels.form'),
        this.$t('common.labels.question'),
        this.$t('common.labels.week_number'),
        this.$t('common.labels.date'),
        this.$t('common.labels.answer'),
      ];

      const formKeys = [
        `\uFEFF${this.$t('common.labels.id')}`,
        this.$t('common.labels.description'),
        this.$t('common.labels.wording'),
        this.$t('common.labels.article'),
      ];

      const questionKeys = [
        `\uFEFF${this.$t('common.labels.question')}`,
        this.$t('common.labels.position'),
        this.$t('common.labels.type'),
        this.$t('common.labels.description'),
        this.$t('common.labels.wording'),
        this.$t('common.labels.variable'),
      ];

      const csv = Utils.exportToCSV(jsonData, formKeys, questionKeys, answerKeys);

      for (let i = 0; i < csv.length; i += 1) {
        if (this.selected.includes(i.toString())) {
          this.downloadCSV(csv, i);
        }
      }
    },
    downloadCSV(csv, i) {
      const blob = new Blob([csv[i]], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      this.setFileName(i, link);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    setFileName(i, link) {
      switch (i) {
        case 0:
          link.setAttribute('download', `${this.$t('common.words.answers')}.csv`);
          break;
        case 1:
          link.setAttribute('download', `${this.$t('common.words.forms')}.csv`);
          break;
        case 2:
          link.setAttribute('download', `${this.$t('common.words.questions')}.csv`);
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'forms',
      fetch: 'fetchForms',
    };
    this.fetch();
  },
};
</script>
